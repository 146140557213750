import { __ } from '@wordpress/i18n';

const getBandoSeverity = (status) => {
    switch (status) {
        case 'SUBMIT':
            return 'success';

        case 'PUBLISH':
            return 'success';

        case 'READY_TO_PUBLISH':
            return 'info';

        case 'DRAFT':
            return 'warning';

        case 'EXPIRED':
            return 'closed';

        default:
            return 'info';
    }
};

export default getBandoSeverity;