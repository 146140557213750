export const mimeTypes = [
    { name: 'PDF', code: 'application/pdf' },
    { name: 'p7m', code: '.p7m,application/pkcs7-mime,application/x-pkcs7-mime' },
    { name: 'ZIP', code: 'application/zip' },
    { name: 'Immagine', code: 'image/*' },
    {
        name: 'Word',
        code: '.doc,.docx,.odt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text'
    },
    {
        name: 'Excel',
        code: '.xls,.xlsx,.ods,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet'
    }
];