import React, { useMemo, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useForm } from 'react-hook-form';

// store
import { storeSet, useStore } from '../../store';

// components
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import FormField from '../../components/FormField';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

// api
import UserService from '../../service/user-service';

// tools
import getDateFromISOstring from '../../helpers/getDateFromISOstring';

const Profile = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const userData = useStore().main.userData();
    const toast = useRef(null);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: useMemo(() => {
            return userData;
        }, [userData]),
        mode: 'onChange'
    });

    const onSubmit = (formData) => {
        storeSet.main.setAsyncRequest();

        UserService.updateUser(userData.id, formData, updateCallback, updateError);
    };

    const updateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.userData(data.data);
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('L\'utente è stato aggiornato!', 'gepafin')
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const updateError = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Profilo utente', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>

            <form className="appForm" onSubmit={handleSubmit(onSubmit)}>

                <div className="appPageSection">
                    <h2>{__('Informazioni personali', 'gepafin')}</h2>
                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="firstName"
                            label={__('Nome', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="Francesco"
                        />

                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="lastName"
                            label={__('Cognome', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="Moli"
                        />

                        <FormField
                            type="textinput"
                            disabled={true}
                            fieldName="codiceFiscale"
                            label={__('Codice fiscale', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="XXXXXXXX"
                        />
                    </div>
                    <div className="appForm__cols">
                        <FormField
                            type="textinput"
                            fieldName="email"
                            label={__('Email', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="user@example.com"
                        />

                        <FormField
                            type="textinput"
                            fieldName="phoneNumber"
                            label={__('Telefono', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            placeholder="1234567"
                        />
                    </div>
                </div>

                <div className="appPageSection">
                    <h2>{__('Consensi', 'gepafin')}</h2>

                    <div className="appForm__switchFieldWrapper">
                        <FormField
                            type="switch"
                            disabled={true}
                            fieldName="privacy"
                            label={''}
                            control={control}
                            errors={errors}
                            onLabel={''}
                            offLabel={''}
                        />
                        <div>
                            {__('Dichiaro di aver preso visione, prima dell\'accesso al portale https://bandi.gepafin.it, dell\' "Informativa Privacy" all\'interno dell\'Appendice 10 dell\'Avviso secondo il Regolamento UE 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personale, nonché alla libera circolazione di tali dati e che abroga la Direttiva 95/46 CE.', 'gepafin')}
                        </div>
                    </div>

                    <div className="appForm__switchFieldWrapper">
                        <FormField
                            type="switch"
                            disabled={true}
                            fieldName="terms"
                            label={''}
                            control={control}
                            errors={errors}
                            onLabel={''}
                            offLabel={''}
                        />
                        <div>
                            {__('Termini e condizioni', 'gepafin')}
                        </div>
                    </div>

                    <div className="appForm__switchFieldWrapper">
                        <FormField
                            type="switch"
                            fieldName="marketing"
                            label={''}
                            control={control}
                            errors={errors}
                            onLabel={''}
                            offLabel={''}
                        />
                        <div>
                            {__('Invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato o comunicazione commerciale riguardanti promozione e vendita di prodotti e servizi della Gepafin, mediante modalità di contatto automatizzate (posta elettronica, PEC, messaggi tramite canali informatici, network ed applicazioni web) e tradizionali (come posta cartacea e chiamate telefoniche con operatore)', 'gepafin')}
                        </div>
                    </div>

                    <div className="appForm__switchFieldWrapper">
                        <FormField
                            type="switch"
                            fieldName="offers"
                            label={''}
                            control={control}
                            errors={errors}
                            onLabel={''}
                            offLabel={''}
                        />
                        <div>
                            {__('Elaborazione, in forma elettronica, dei dati relativi ai rapporti e servizi forniti, per l’analisi di comportamenti e preferenze della clientela, da utilizzare a scopo commerciale, per la individuazione ed offerta di prodotti e servizi di suo interesse', 'gepafin')}
                        </div>
                    </div>

                    <div className="appForm__switchFieldWrapper">
                        <FormField
                            type="switch"
                            fieldName="thirdParty"
                            label={''}
                            control={control}
                            errors={errors}
                            onLabel={''}
                            offLabel={''}
                        />
                        <div>
                            {__('Comunicazione dei miei dati ad altre società in ambito bancario, finanziario od assicurativo e enti pubblici che li tratteranno per invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato o comunicazione commerciale riguardanti loro prodotti o servizi, mediante le modalità automatizzate e tradizionali di comunicazione sopra indicate', 'gepafin')}
                        </div>
                    </div>
                </div>

                <div className="appPageSection">
                    <h2>{__('Utenti Associati', 'gepafin')}</h2>
                    <div className="appForm__cols">
                        <FormField
                            type="select"
                            disabled={true}
                            fieldName="language"
                            defaultValue={'it'}
                            label={__('Lingua predefinita', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{ required: __('È obbligatorio', 'gepafin') }}
                            options={[
                                { label: __('Italiano', 'gepafin'), name: 'it' }
                            ]}
                        />

                        <FormField
                            type="select"
                            disabled={true}
                            fieldName="timezone"
                                defaultValue={'Europe/Rome'}
                                label={__('Fuso Orario', 'gepafin')}
                                control={control}
                                errors={errors}
                                config={{ required: __('È obbligatorio', 'gepafin') }}
                                options={[
                                    { label: __('Europe/Rome', 'gepafin'), name: 'Europe/Rome' }
                                ]}
                            />

                            <FormField
                                type="select"
                                disabled={true}
                                fieldName="dateformat"
                                defaultValue={'DD/MM/YY'}
                                label={__('Formato Data', 'gepafin')}
                                control={control}
                                errors={errors}
                                config={{ required: __('È obbligatorio', 'gepafin') }}
                                options={[
                                    { label: __('DD/MM/YY', 'gepafin'), name: 'DD/MM/YY' }
                                ]}
                            />
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Sicurezza', 'gepafin')}</h2>
                        <div className="appForm__row">
                            <label>{__('Ultimo accesso', 'gepafin')}</label>
                            <span>{getDateFromISOstring(userData.lastLogin)}</span>
                        </div>
                    </div>

                    <div className="appPage__spacer"></div>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni rapide', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection">
                        <div className="appPageSection__actions">
                            <Button
                                disabled={isAsyncRequest}
                                label={__('Salva modifiche', 'gepafin')}
                                icon="pi pi-check" iconPos="right"/>
                        </div>
                    </div>
            </form>

        </div>
)

}

export default Profile;