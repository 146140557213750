import React, { forwardRef, useImperativeHandle, useEffect, useState, useMemo, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty, isNil, is } from 'ramda';
import { klona } from 'klona';
import { TZDate } from '@date-fns/tz';

// components
import FormField from '../../../../components/FormField';
import FormFieldRepeater from '../../../../components/FormFieldRepeater';
import FormFieldRepeaterFaq from '../../../../components/FormFieldRepeaterFaq';
import BandoEditFormActions from '../BandoEditFormActions';
import UnsavedChangesDetector from '../../../../components/UnsavedChangesDetector';
import { Toast } from 'primereact/toast';

// api
import BandoService from '../../../../service/bando-service';
import LookupdataService from '../../../../service/lookupdata-service';

// tools
import { isEmail } from '../../../../helpers/validators';

// store
import { storeSet } from '../../../../store';
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';
import getTimeParsedFromString from '../../../../helpers/getTimeParsedFromString';

const BandoEditFormStep1 = forwardRef(function ({ initialData, getFormErrors, status }, ref) {
    const navigate = useNavigate();
    const [aimedToOptions, setAimedToOptions] = useState([]);
    const [faqOptions, setFaqOptions] = useState([]);
    const [formInitialData, setFormInitialData] = useState(initialData);
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        register,
        trigger,
        getValues,
        clearErrors
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData;
        }, [formInitialData]), mode: 'onChange'
    });
    const values = getValues();
    const toast = useRef(null);

    const onSubmit = (formData) => {
        /*if (!isNil(formData.dates) && formData.dates.length) {
            formData.dates = formData.dates.map(v => {
                if (is(String, v)) {
                    return v;
                } else {
                    const tzAwareDate = new TZDate(v, 'Europe/Berlin');
                    return tzAwareDate.toISOString().substring(0, 19);
                }
            });
        }

        storeSet.main.setAsyncRequest();
        if (!formData.id) {
            BandoService.createBando(formData, createCallback, errCreateCallback);
        } else {
            BandoService.updateBandoStep1(formData.id, formData, createCallback, errCreateCallback);
        }*/
    };

    const onSaveDraft = () => {
        trigger();
        const formData = getValues();
        if (!isNil(formData.dates) && formData.dates.length) {
            formData.dates = formData.dates.map(v => {
                if (is(String, v)) {
                    return v;
                } else {
                    const tzAwareDate = new TZDate(v, 'Europe/Berlin');
                    return tzAwareDate.toISOString().substring(0, 19);
                }
            });
        }
        if (!isNil(formData.startTime)) {
            if (!is(String, formData.startTime)) {
                const tzAwareDate = new TZDate(formData.startTime, 'Europe/Berlin');
                formData.startTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }

        if (!isNil(formData.endTime)) {
            if (!is(String, formData.endTime)) {
                const tzAwareDate = new TZDate(formData.endTime, 'Europe/Berlin');
                formData.endTime = tzAwareDate.toISOString().substring(11, 16);
            }
        }

        storeSet.main.setAsyncRequest();
        if (!formData.id) {
            BandoService.createBando(formData, createCallback, errCreateCallback);
        } else {
            BandoService.updateBandoStep1(formData.id, formData, createCallback, errCreateCallback);
        }
    }

    const createCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'SUCCESS') {
            toast.current.show({
                severity: 'success',
                summary: '',
                detail: __('Il bando è stato aggiornato corretamente!', 'gepafin')
            });
            const values = getValues();
            if (!values.id && data.data.id) {
                navigate(`/bandi/${data.data.id}`);
            } else {
                setFormInitialData(data.data);
            }
        }
    }

    const errCreateCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const openPreview = () => {
        navigate(`/bandi/${values.id}/preview`);
    }

    const openPreviewEvaluation = () => {
        navigate(`/bandi/${values.id}/preview-evaluation`);
    }

    const lookupdataCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const aimedTo = data.data
                .filter(o => o.type === 'AIMED_TO')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setAimedToOptions(aimedTo);
            const faqItems = data.data
                .filter(o => o.type === 'FAQ')
                .map(o => {
                    delete o.type;
                    return {
                        ...o,
                        lookUpDataId: o.id,
                        id: null
                    };
                });
            setFaqOptions(faqItems);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errLookupdataCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
    }

    const shouldDisableField = () => {
        return values.status === 'PUBLISH'
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                isFormValid: () => {
                    return isValid;
                },
                getErrors: () => {
                    return errors;
                },
                getValues: () => {
                    return getValues();
                }
            }
        }, [errors, isValid]);

    useEffect(() => {
        storeSet.main.formInitialData(initialData);
        setFormInitialData(initialData);
    }, [initialData]);

    useEffect(() => {
        const newFormData = klona(formInitialData);
        if (!isNil(formInitialData.dates) && formInitialData.dates.length) {
            newFormData.dates = formInitialData.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
        }
        if (!isNil(formInitialData.startTime) && !isEmpty(formInitialData.startTime)) {
            newFormData.startTime = is(String, formInitialData.startTime)
                ? getTimeParsedFromString(formInitialData.startTime)
                : formInitialData.startTime;
        }
        if (!isNil(formInitialData.endTime) && !isEmpty(formInitialData.endTime)) {
            newFormData.endTime = is(String, formInitialData.endTime)
                ? getTimeParsedFromString(formInitialData.endTime)
                : formInitialData.endTime;
        }

        Object.keys(newFormData).map(v => setValue(v, newFormData[v]));
    }, [formInitialData]);

    useEffect(() => {
        trigger().then(() => clearErrors());
        LookupdataService.getItems(lookupdataCallback, errLookupdataCallback, [['type', ['AIMED_TO', 'FAQ']]]);

        return () => {
            storeSet.main.formInitialData({});
        }
    }, []);

    return (
        <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChangesDetector getValuesFn={getValues}/>
            <FormField
                type="switch"
                disabled={shouldDisableField()}
                fieldName="confidi"
                label={__('Bando Confidi', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['confidi']}
                onLabel={__('Si', 'gepafin')}
                offLabel={__('No', 'gepafin')}
            />

            <FormField
                type="textinput"
                disabled={shouldDisableField()}
                fieldName="name"
                label={__('Titolo del Bando', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['name']}
                config={{ required: __('È obbligatorio', 'gepafin') }}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField()}
                fieldName="descriptionShort"
                label={__('Descrizione breve', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['descriptionShort']}
                config={{
                    required: __('È obbligatorio', 'gepafin')
                }}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField()}
                fieldName="descriptionLong"
                label={__('Descrizione completa', 'gepafin')}
                control={control}
                rows={7}
                errors={errors}
                defaultValue={values['descriptionLong']}
                config={{
                    required: __('È obbligatorio', 'gepafin'),
                    minLength: 200
                }}
                infoText={__('Almeno 200 caratteri', 'gepafin')}
            />

            <FormFieldRepeater
                data={values['aimedTo']}
                disabled={shouldDisableField()}
                setDataFn={setValue}
                fieldName="aimedTo"
                options={aimedToOptions}
                errors={errors}
                register={register}
                trigger={trigger}
                config={{
                    validate: {
                        minOneItem: v => (v && !isEmpty(v)) || __('Almeno 1 tipo di destinatari', 'gepafin'),
                        noEmptyValue: v => v.filter(o => isEmpty(o.value)).length === 0 || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}
                label={<>{__('A chi si rivolge', 'gepafin')}*
                    <span>{__('(almeno 1 tipo di destinatari)', 'gepafin')}</span></>}
            />

            <FormField
                type="wysiwyg"
                disabled={shouldDisableField()}
                fieldName="documentationRequested"
                label={__('Documentazione richiesta', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['documentationRequested']}
                config={{
                    required: __('È obbligatorio', 'gepafin')
                }}
            />

            <FormField
                type="datepickerrange"
                disabled={shouldDisableField()}
                fieldName="dates"
                label={__('Dati di pubblicazione', 'gepafin')}
                control={control}
                errors={errors}
                defaultValue={values['dates']}
                config={{ required: __('È obbligatorio', 'gepafin') }}
            />

            <div className="appForm__cols">
                <FormField
                    type="datepicker"
                    disabled={shouldDisableField()}
                    fieldName="startTime"
                    label={__('Ora di inizio', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['startTime']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    timeOnly={true}
                />

                <FormField
                    type="datepicker"
                    disabled={shouldDisableField()}
                    fieldName="endTime"
                    label={__('Ora di fine', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['endTime']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    timeOnly={true}
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="numberinput"
                    disabled={shouldDisableField()}
                    fieldName="amount"
                    label={__('Dotazione del Bando', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amount']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />

                <FormField
                    type="numberinput"
                    disabled={shouldDisableField()}
                    fieldName="amountMin"
                    label={__('Importo minimo per Progetto', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amountMin']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />

                <FormField
                    type="numberinput"
                    disabled={shouldDisableField()}
                    fieldName="amountMax"
                    label={__('Importo massimo per Progetto', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['amountMax']}
                    config={{ required: __('È obbligatorio', 'gepafin') }}
                    inputgroup={true}
                    icon="€"
                />
            </div>

            <div className="appForm__cols">
                <FormField
                    type="textinput"
                    disabled={shouldDisableField()}
                    fieldName="email"
                    label={__('Email', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['email']}
                    config={{
                        required: __('È obbligatorio', 'gepafin'),
                        validate: {
                            isEmail
                        }
                    }}
                />

                <FormField
                    type="textinput"
                    disabled={shouldDisableField()}
                    fieldName="phoneNumber"
                    label={__('Telefono', 'gepafin')}
                    control={control}
                    errors={errors}
                    defaultValue={values['phoneNumber']}
                    config={{
                        pattern: /^[0-9]/
                    }}
                    inputgroup={true}
                    icon="+39"
                />
            </div>

            <FormFieldRepeaterFaq
                data={values['faq']}
                setDataFn={setValue}
                fieldName="faq"
                options={faqOptions}
                errors={errors}
                register={register}
                trigger={trigger}
                config={{
                    validate: {
                        noEmptyValue: v => v
                            .filter(o => isEmpty(o.question) || isEmpty(o.answer)).length === 0 || __('Non lasciare il valore vuoto', 'gepafin')
                    }
                }}
                label={__('FAQ', 'gepafin')}/>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni', 'gepafin')}</span>
            </div>

            <Toast ref={toast}/>
            <BandoEditFormActions
                id={values.id}
                status={status}
                submitFn={onSaveDraft}
                openPreview={openPreview}
                openPreviewEvaluation={openPreviewEvaluation}/>
        </form>
    )
})

export default BandoEditFormStep1;