import { __ } from '@wordpress/i18n';

const getBandoLabel = (status) => {
    switch (status) {
        case 'SUBMIT':
            return __('Inviato', 'gepafin');

        case 'PUBLISH':
            return __('Pubblicato', 'gepafin');

        case 'READY_TO_PUBLISH':
            return __('Pronto', 'gepafin');

        case 'DRAFT':
            return __('Bozza', 'gepafin');

        case 'EXPIRED':
            return __('Scaduto', 'gepafin');

        default:
            return '';
    }
};

export default getBandoLabel;