import React from 'react';
import { __ } from '@wordpress/i18n';
import { isNil } from 'ramda';

// components
import { Button } from 'primereact/button';

const BandoEditFormActions = ({ id, openPreview, openPreviewEvaluation, submitFn }) => {
    return (
        <div className="appPageSection">
            <div className="appPageSection__actions">
                <Button
                    type="button"
                    onClick={submitFn}
                    label={__('Salva bozza', 'gepafin')} icon="pi pi-save" iconPos="right"/>
                <Button
                    type="button"
                    disabled={isNil(id)}
                    outlined
                    onClick={openPreview}
                    label={__('Anteprima beneficiario', 'gepafin')} icon="pi pi-eye" iconPos="right"/>
                <Button
                    type="button"
                    disabled={true}
                    outlined
                    onClick={openPreviewEvaluation}
                    label={__('Anteprima pre-istruttoria', 'gepafin')} icon="pi pi-eye"
                    iconPos="right"/>
            </div>
        </div>
    )
}

export default BandoEditFormActions;