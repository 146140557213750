import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class CompanyService {

    static createCompany = (body, callback, errCallback) => {
        NetworkService.post(`${API_BASE_URL}/company`, body, callback, errCallback);
    };

    static updateCompany = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/company/${id}`, body, callback, errCallback);
    };

    static checkVat = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/company/vatNumber`, callback, errCallback, queryParams);
    };

    static getCompanyForUser = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/company/user/${id}`, callback, errCallback);
    };

    static getCompanyDelega = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/company/${id}/delegation`, callback, errCallback);
    };

    static downloadCompanyDelega = (id, body, callback, errCallback) => {
        NetworkService.postBlob(`${API_BASE_URL}/company/${id}/delegation/download`, body, callback, errCallback);
    };

    static uploadCompanyDelega = (id, body, callback, errCallback, queryParams) => {
        NetworkService.postMultiPart(`${API_BASE_URL}/company/${id}/delegation/upload`, body, callback, errCallback, queryParams);
    };

    static deleteDelega = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/company/${id}/delegation`, {}, callback, errCallback);
    };
}
