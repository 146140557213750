import React, { useState, useEffect} from 'react';
import { __ } from '@wordpress/i18n';
import { uniq } from 'ramda';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// store
import { storeSet } from '../../../../store';

// api
import BandoService from '../../../../service/bando-service';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Link } from 'react-router-dom';


const LatestBandiTable = () => {
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        // TODO
        /*const items = [
            {
                name: 'Bando Innovazione 2024',
                start_date: '2024-08-08T00:00:00+00:00',
                end_date: '2024-08-30T00:00:00+00:00',
                submissions: 24,
                status: 'PUBLISH',
                id: 11
            },
            {
                name: 'Bando Sostenibilità 2024',
                start_date: '2024-07-28T00:00:00+00:00',
                end_date: '2024-08-15T00:00:00+00:00',
                submissions: 35,
                status: 'PUBLISH',
                id: 9
            },
            {
                name: 'Bando A',
                start_date: '2024-06-28T00:00:00+00:00',
                end_date: '2024-06-15T00:00:00+00:00',
                submissions: 2,
                status: 'EXPIRED',
                id: 2
            }
        ]
        setItems(getFormattedBandiData(items));
        setStatuses(uniq(items.map(o => o.status)))
        setLoading(false);
        initFilters();*/

        storeSet.main.setAsyncRequest();
        BandoService.getBandi(getCallback, errGetCallbacks);
    }, []);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newItems = data.data.filter(o => o.status === 'PUBLISH');
            setItems(getFormattedBandiData(newItems));
            setStatuses(uniq(data.data.map(o => o.status)))
            initFilters();
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallbacks = (data) => {
        console.log('errGetCallbacks', data)
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.start_date = new Date(d.dates[0]);
            d.end_date = new Date(d.dates[1]);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            start_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            end_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined onClick={clearFilter} />
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={__('Cerca', 'gepafin')} />
                </IconField>
            </div>
        );
    };

    const dateStartBodyTemplate = (rowData) => {
        return formatDate(rowData.start_date);
    };

    const dateEndBodyTemplate = (rowData) => {
        return formatDate(rowData.end_date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)} />;
    };

    const actionsBodyTemplate = (rowData) => {
        return <Link to={`/bandi/${rowData.id}`}>
            <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small" iconPos="right" />
        </Link>
    }

    const header = renderHeader();

    return(
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={10} loading={loading} dataKey="id"
                       filters={filters}
                       globalFilterFields={['name', 'status']}
                       header={header}
                       emptyMessage={__('Nessun dato disponibile', 'gepafin')}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="name" header={__('Nome Bando', 'gepafin')} filter filterPlaceholder="Search by name"
                        style={{ minWidth: '12rem' }}/>
                <Column header={__('Data Pubblicazione', 'gepafin')} filterField="start_date" dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateStartBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Data Scadenza', 'gepafin')} filterField="end_date" dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        style={{ width: '120px' }} body={statusBodyTemplate} />
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default LatestBandiTable;