import { storeGet } from '../store';

export class NetworkService {
    static TOKEN_KEY
    static REFRESH_TOKEN_KEY

    static postEmptyResponse = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': storeGet.main.getToken(),
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(body)
        })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.status)
                else
                    callback()
            })
            .catch(err => errorCallback(err));
    };

    static putEmptyResponse = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': storeGet.main.getToken(),
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(body)
        })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.status)
                else
                    callback()
            })
            .catch(err => errorCallback(err));
    };

    static post = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static postMultiPart = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                //'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: body
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static postBlob = (url, body, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.blob(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static unauthorizedPost = (url, body, callback, errorCallback, queryParams) => {
        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static patch = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static put = (url, body, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            url += '?'

            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static unauthorizedPostEmptyResponse = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(body)
        })
            .then(data => callback(data))
            .catch(err => errorCallback(err));

    };

    static unauthorizedPutEmptyResponse = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(body)
        })
            .then(data => callback(data))
            .catch(err => errorCallback(err));

    };

    static unauthorizedGet = (url, queryParams, callback, errorCallback) => {
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));

    };

    static unauthorizedPatch = (url, body, callback, errorCallback) => {
        fetch(url, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));

    };

    static isNotBlank(value) {
        return value !== null && value !== undefined && value !== ''
    }

    static get = (url, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            url += '?'

            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            }
        })
            .then(async response => {
                let status = response.status;
                //console.log('status in fetch:', status)
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599) {
                    errorCallback(data.response)
                } else {
                    callback(data.response)
                }
            })
            .catch(err => errorCallback(err));

    };

    static getBlob = (url, callback, errorCallback, queryParams) => {

        if (queryParams) {
            url += '?'
            for (let i = 0; i < queryParams.length; i++) {
                if (queryParams[i] && this.isNotBlank(queryParams[i][0]) && this.isNotBlank(queryParams[i][1])) {
                    let param = queryParams[i][0] + '=' + queryParams[i][1]

                    if (i !== queryParams.length - 1)
                        param += '&'

                    url += param;
                }
            }

            if (url.charAt(url.length) === '&')
                url = url.substring(0, url.length - 1);
        }

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.blob(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));
    };

    static promiseGet = async (url, queryParams = null) => {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
                'Access-Control-Allow-Origin': '*'
            }
        });

        const json = await response.json();
        return json;
    }

    static deleteEmptyResponse = (url, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            let params = '?'

            for (let i = 0; i < queryParams.length; i++) {
                params += queryParams[i][0] + '=' + queryParams[i][1]
                if (queryParams.length !== i + 1)
                    params += '&'
                url += params
                params = ''
            }

        }

        fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': storeGet.main.getToken(),
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.status)
                else
                    callback()
            })
            .catch(err => errorCallback(err));
    }

    static delete = (url, body, callback, errorCallback, queryParams = null) => {
        if (queryParams) {
            let params = '?'

            for (let i = 0; i < queryParams.length; i++) {
                params += queryParams[i][0] + '=' + queryParams[i][1]
                if (queryParams.length !== i + 1)
                    params += '&'
                url += params
                params = ''
            }

        }
        fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + storeGet.main.getToken(),
            },
            body: JSON.stringify(body)
        })
            .then(async response => {
                let status = response.status;
                return { response: await response.json(), status: status }
            })
            .then(data => {
                if (data.status >= 400 && data.status <= 599)
                    errorCallback(data.response)
                else
                    callback(data.response)
            })
            .catch(err => errorCallback(err));

    };
}
