import React from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';

// components
import MyLatestSubmissionsTable from '../DashboardBeneficiario/components/MyLatestSubmissionsTable';
import { Button } from 'primereact/button';

const Applications = () => {
    const navigate = useNavigate();

    const gotToBandiDisponibili = () => {
        navigate('/bandi')
    }

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Domande in Lavorazione', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <MyLatestSubmissionsTable/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={gotToBandiDisponibili}
                        label={__('Bandi disponibili', 'gepafin')} icon="pi pi-bookmark" iconPos="right"/>
                    {/*<Button
                        disabled={true}
                        outlined
                        onClick={() => {
                        }}
                        label={__('Contatta assistenza', 'gepafin')} icon="pi pi-envelope" iconPos="right"/>*/}
                </div>
            </div>
        </div>
    )
}

export default Applications;