import React, { useRef } from 'react';
import { __ } from '@wordpress/i18n';

// components
import { Toolbar } from 'primereact/toolbar';
import { Link } from 'react-router-dom';
import LogoIcon from '../../../../icons/LogoIcon';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import TopBarProfileMenu from '../../../../components/TopBarProfileMenu';

const AppTopbar = () => {
    const menuLeft = useRef(null);

    const startContent = <Link to="/">
        <LogoIcon/>
    </Link>;

    const endContent = <div className="topBar__endContent">
        <IconField iconPosition="right">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText v-model="value1" placeholder={__('Cerca', 'gepafin')} disabled={true}/>
        </IconField>
        <i className="pi pi-bell p-overlay-badge topBar__icon">
            <Badge value="0"></Badge>
        </i>
        <i className="pi pi-envelope p-overlay-badge topBar__icon">
            <Badge severity="danger"></Badge>
        </i>
        <Button
            className="topBar__profileBtn"
            outlined
            onClick={(event) => menuLeft.current.toggle(event)} aria-controls="topBar_profileMenu" aria-haspopup>
            <i className="pi pi-user"></i>
            <i className="pi pi-chevron-down"></i>
        </Button>
        <TopBarProfileMenu menuLeftRef={menuLeft}/>
    </div>

    return(
        <Toolbar start={startContent} end={endContent} className="topBar"/>
    )
}

export default AppTopbar;