import { useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import equal from 'fast-deep-equal';
//import { diff } from 'deep-object-diff';

// store
import { storeGet } from '../../store';

const UnsavedChangesDetector = ({ getValuesFn }) => {
    const warnIfUnsavedChanges = (event) => {
        const formData = getValuesFn();
        const initial = storeGet.main.formInitialData();
        const isEqual = equal(initial, formData);
        // TODO
        //console.log('isEqual', isEqual, initial, formData, diff(initial, formData))
        if (!isEqual) {
            event.returnValue = __('You have unsaved changes. If you proceed, they will be lost.', 'gepafin');
        }

        return event.returnValue;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', warnIfUnsavedChanges);

        return () => {
            window.removeEventListener('beforeunload', warnIfUnsavedChanges);
        }
    }, []);
}

export default UnsavedChangesDetector;
