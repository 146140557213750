import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';

const Datepicker = ({
                        fieldName,
                        label,
                        control,
                        errors,
                        defaultValue = new Date(),
                        config = {},
                        infoText = null,
                        minDate = null,
                        maxDate = null,
                        disabled = false,
                        timeOnly = false
                    }) => {
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                rules={config}
                render={({ field, fieldState }) => (
                    <Calendar id={field.name}
                              disabled={disabled}
                              value={field.value ?? []}
                              onChange={(e) => field.onChange(e.value)}
                              dateFormat="dd/mm/yy"
                              hourFormat="24"
                              timeOnly={timeOnly}
                              showIcon
                              minDate={minDate}
                              maxDate={maxDate}
                              className={classNames({ 'p-invalid': fieldState.invalid })}/>
                )}/>
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default Datepicker;