import React, { useRef, useState, useEffect } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { isEmpty } from 'ramda';

// tools
import AuthenticationService from '../../service/authentication-service';

// store
import { useStore } from '../../store';

// components
import FormField from '../../components/FormField';
import LogoIcon from '../../icons/LogoIcon';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

const ResetPassword = () => {
    const token = useStore().main.token();
    const [loading, setLoading] = useState(false);
    const [resetPassToken, setResetPassToken] = useState('');
    const errorMsgs = useRef(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        register,
        setValue
    } = useForm({ mode: 'onChange' });

    const onSubmit = (formData) => {
        errorMsgs.current.clear();
        setLoading(true);
        const request = {
            ...formData
        }

        AuthenticationService.forgotPassword(request, getCallback, errCallback);
    };

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setResetPassToken(data.data)
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const errCallback = (err) => {
        errorMsgs.current.show([
            {
                sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err),
                closable: true
            }
        ]);
        setLoading(false);
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            setLoading(true);
            window.location.replace('/')
        }
    }, [token]);

    useEffect(() => {
        setValue('token', resetPassToken);
        reset();
    }, [resetPassToken])

    return (
        <div className={classNames(['appPage', 'appPageLogin'])}>
            <div className="appPageLogin__wrapper">
                <LogoIcon/>

                <h1>{__('Password dimenticata', 'gepafin')}</h1>

                <Messages ref={errorMsgs}/>

                <div className="appPage__spacer"></div>

                <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                    <FormField
                        type="textinput"
                        fieldName="email"
                        label={__('Email', 'gepafin')}
                        control={control}
                        errors={errors}
                        config={{ required: __('È obbligatorio', 'gepafin') }}
                        placeholder="sample@example.com"
                    />

                    {!isEmpty(resetPassToken)
                        ? <input
                            type="hidden"
                            name="token"
                            {...register('test', {
                                required: true
                            })}
                        /> : null}

                    {!isEmpty(resetPassToken)
                        ? <FormField
                            type="textinput"
                            inputtype="password"
                            fieldName="newPassword"
                            label={__('Password', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    passEqual: (value, values) => values.confirmPassword === value
                                }
                            }}
                        /> : null}

                    {!isEmpty(resetPassToken)
                        ? <FormField
                            type="textinput"
                            inputtype="password"
                            fieldName="confirmPassword"
                            label={__('Conferma Password', 'gepafin')}
                            control={control}
                            errors={errors}
                            config={{
                                required: __('È obbligatorio', 'gepafin'),
                                validate: {
                                    passEqual: (value, values) => values.newPassword === value
                                }
                            }}
                        /> : null}

                    <Button
                        label={__('Invia', 'gepafin')}
                        disabled={loading}/>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;