import { Route, Routes } from 'react-router-dom';

// components
import PageNotFound from './pages/PageNotFound';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import DashboardBeneficiario from './pages/DashboardBeneficiario';
import BandoViewBeneficiario from './pages/BandoViewBeneficiario';
import DefaultLayout from './layouts/DefaultLayout';
import Bandi from './pages/Bandi';
import BandoEdit from './pages/BandoEdit';
import BandoView from './pages/BandoView';
import BandoFormsEdit from './pages/BandoFormsEdit';
import BandoForms from './pages/BandoForms';
import BandoFormsPreview from './pages/BandoFormsPreview';
import BandoFlowEdit from './pages/BandoFlowEdit';
import Applications from './pages/Applications';
import BandoApplication from './pages/BandoApplication';
import Registration from './pages/Registration';
import BandiBeneficiario from './pages/BandiBeneficiario';
import LoginAdmin from './pages/LoginAdmin';
import Profile from './pages/Profile';
import ProfileCompany from './pages/ProfileCompany';
import Users from './pages/Users';
import AddCompany from './pages/AddCompany';
import ResetPassword from './pages/ResetPassword';

const routes = ({ role, chosenCompanyId }) => {

    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Dashboard/> : null}
                    {'ROLE_BENEFICIARY' === role ? <DashboardBeneficiario/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Bandi/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandiBeneficiario/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandoViewBeneficiario/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoView/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/preview-evaluation" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoView/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoForms/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms/:formId" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFormsEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms/:formId/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFormsPreview/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/flow" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFlowEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/imieibandi" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <Applications/> : null}
                </DefaultLayout>}/>
                <Route path="/imieibandi/:id/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandoApplication/> : null}
                </DefaultLayout>}/>
                <Route path="/profilo" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Profile/> : null}
                    {'ROLE_BENEFICIARY' === role ? <Profile/> : null}
                </DefaultLayout>}/>
                <Route path="/profilo-aziendale" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role && chosenCompanyId > 0 ? <ProfileCompany/> : <PageNotFound/>}
                </DefaultLayout>}/>
                <Route path="/agguingi-azienda" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <AddCompany/> : null}
                </DefaultLayout>}/>
                <Route path="/utenti" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Users/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
            </Route>
            <Route exact path="/reset-password" element={<ResetPassword/>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/loginAdmin" element={<LoginAdmin/>}/>
            <Route exact path="/registration" element={<Registration/>}/>
            <Route path="*" element={<PageNotFound/>}/>
        </Routes>)
};

export default routes;
