const initialStore = {
    // ui related
    isAsyncRequest: 0, // number
    isError404: false,
    isRedirectedOnceNoCompany: false,
    // user
    userData: {},
    token: '',
    companies: [],
    chosenCompanyId: 0,
    // bando form
    formInitialData: {},
    // form builder
    formId: 0,
    formLabel: '',
    formElements: [],
    elementItems: [],
    activeElement: '',
    draggingElementId: 0,
    // flow
    flowData: [],
    flowForms: [],
    flowEdges: []
}

export default initialStore;