import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, isNil } from 'ramda';

// components
import AllUsersTable from './components/AllUsersTable';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import UserService from '../../service/user-service';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import { storeSet } from '../../store';
import { klona } from 'klona';
import { classNames } from 'primereact/utils';

const Users = () => {
    const [isVisibleEditDialog, setIsVisibleEditDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        role: ''
    });
    const [roles, setRoles] = useState([]);

    const onCreateNewUser = () => {
        setIsVisibleEditDialog(true);
    }

    const headerEditDialog = () => {
        return <span>{__('Aggiungi utente', 'gepafin')}</span>
    }

    const hideEditDialog = () => {
        setIsVisibleEditDialog(false);
        setNewUserData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            role: ''
        });
    }

    const saveEditDialog = () => {

    }

    const onChangeEditItem = (value, key) => {
        const userData = klona(newUserData);
        userData[key] = value;
        setNewUserData(userData);
    }

    const footerEditDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideEditDialog} outlined/>
            <Button
                type="button"
                disabled={isEmpty(newUserData)}
                label={__('Salva', 'gepafin')} onClick={saveEditDialog}/>
        </div>
    }

    const getRolesCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const roles = data.data.map(o => ({
                name: o.roleName,
                value: o.id
            }));
            setRoles(roles)
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetRolesCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        if (isVisibleEditDialog) {
            UserService.getRoles(getRolesCallback, errGetRolesCallback)
        }
    }, [isVisibleEditDialog]);

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Gestione utenti', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={onCreateNewUser}
                        label={__('Crea nuovo')} icon="pi pi-plus" iconPos="right"/>
                </div>

                <AllUsersTable doRefresh={true}/>

                <Dialog
                    visible={isVisibleEditDialog}
                    modal
                    header={headerEditDialog}
                    footer={footerEditDialog}
                    style={{ maxWidth: '600px', width: '100%' }}
                    onHide={hideEditDialog}>
                    <div className="appPage__spacer"></div>
                    <div className="appForm__cols">
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(newUserData.firstName) || isNil(newUserData.firstName) })}>{__('Nome', 'gepafin')}*</label>
                            <InputText value={newUserData.firstName}
                                       invalid={isEmpty(newUserData.firstName) || isNil(newUserData.firstName)}
                                       onChange={(e) => onChangeEditItem(e.target.value, 'firstName')}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(newUserData.lastName) || isNil(newUserData.lastName) })}>{__('Cognome', 'gepafin')}*</label>
                            <InputText value={newUserData.lastName}
                                       invalid={isEmpty(newUserData.lastName) || isNil(newUserData.lastName)}
                                       onChange={(e) => onChangeEditItem(e.target.value, 'lastName')}/>
                        </div>
                    </div>
                    <div className="appForm__cols">
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(newUserData.email) || isNil(newUserData.email) })}>{__('Email', 'gepafin')}*</label>
                            <InputText value={newUserData.email}
                                       invalid={isEmpty(newUserData.email) || isNil(newUserData.email)}
                                       onChange={(e) => onChangeEditItem(e.target.value, 'email')}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(newUserData.phoneNumber) || isNil(newUserData.phoneNumber) })}>{__('Telefono', 'gepafin')}</label>
                            <InputText value={newUserData.phoneNumber}
                                       invalid={isEmpty(newUserData.phoneNumber) || isNil(newUserData.phoneNumber)}
                                       onChange={(e) => onChangeEditItem(e.target.value, 'phoneNumber')}/>
                        </div>
                    </div>
                    <div className="appForm__field">
                        <label className={classNames({ 'p-error': isEmpty(newUserData.role) || isNil(newUserData.role) })}>{__('Ruolo', 'gepafin')}</label>
                        <Dropdown
                            value={newUserData.role}
                            invalid={isEmpty(newUserData.role) || isNil(newUserData.role)}
                            onChange={(e) => onChangeEditItem(e.value, 'role')}
                            options={roles}
                            optionLabel="name"
                            optionValue="value"/>
                    </div>
                    <div className="appPage__spacer"></div>
                </Dialog>
            </div>
        </div>
    )
}

export default Users;