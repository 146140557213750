import React from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';

// components
import AllBandiAccordion from './components/AllBandiAccordion';
import { Button } from 'primereact/button';

const BandiBeneficiario = () => {
    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Bandi disponibili', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <AllBandiAccordion/>
            </div>
        </div>
    )
}

export default BandiBeneficiario;