import React, { useRef, useEffect, useState } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { classNames } from 'primereact/utils';
import { isEmpty, isNil } from 'ramda';

// store
import { storeSet, useStore } from '../../store';

// api
import AuthenticationService from '../../service/authentication-service';

// components
import LogoIcon from '../../icons/LogoIcon';
import { Messages } from 'primereact/messages';
import { useSearchParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';

const API_BASE_URL = process.env.REACT_APP_API_ADDRESS;

const Login = () => {
    const token = useStore().main.token();
    const errorMsgs = useRef(null);
    const [loading, setLoading] = useState(false);
    const [visibleCacheFaq, setVisibleCacheFaq] = useState(false);
    let [searchParams] = useSearchParams();
    const { origin } = window.location;

    const loginWithSpid = () => {
        if (!loading) {
            window.location.replace(`${API_BASE_URL}/saml2/authenticate/loginumbria`);
        }
    }

    const validateCallback = (data) => {
        //console.log('login validateCallback', data)
        if (data.status === 'SUCCESS') {
            storeSet.main.setAuthData({
                token: data.data.token,
                userData: data.data.user
            });
        } else {
            errorMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        setLoading(false);
    }

    const validateError = (err) => {
        errorMsgs.current.show([
            {
                sticky: true, severity: 'error', summary: '',
                detail: sprintf(__('%s', 'gepafin'), err.message),
                closable: true
            }
        ]);
        setLoading(false);
    }

    const openCacheFaq = (e) => {
        e.preventDefault();
        setVisibleCacheFaq(true);
    }

    const hideCacheFaq = () => {
        setVisibleCacheFaq(false);
    }

    useEffect(() => {
        if (!isEmpty(token)) {
            window.location.replace('/')
        }
    }, [token]);

    useEffect(() => {
        const temp_token = searchParams.get('temp_token');
        //console.log('login temp_token', temp_token);
        if (!isNil(temp_token) && !isEmpty(temp_token)) {
            errorMsgs.current.clear();
            AuthenticationService.validateExistingUser(temp_token, validateCallback, validateError);
        }
    }, [searchParams]);

    return (
        <div className={classNames(['appPage', 'appPageLogin'])}>
            <div className="appPageLogin__wrapper">
                <LogoIcon/>

                <h1>{__('Accedi o Registrati', 'gepafin')}</h1>

                <div className="appPage__spacer"></div>

                <Messages ref={errorMsgs}/>

                <button className="appPageLogin__spidBtn" onClick={loginWithSpid}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1156_13356)">
                            <path
                                d="M30.5767 15.0422C30.5767 23.3285 23.8629 30.0422 15.5767 30.0422C7.29552 30.0422 0.57666 23.3285 0.57666 15.0422C0.57666 6.75599 7.29552 0.0422363 15.5767 0.0422363C23.8629 0.0422363 30.5767 6.75599 30.5767 15.0422Z"
                                fill="white"/>
                            <path
                                d="M15.6175 16.3288C14.3717 16.3288 13.3455 15.9102 12.5389 15.0627C11.7322 14.2203 11.3289 13.1736 11.3289 11.9279C11.3289 10.677 11.7322 9.6406 12.5338 8.81351C13.3353 7.98642 14.3564 7.57287 15.6073 7.57287C16.853 7.57287 17.869 7.99152 18.6502 8.84414C19.4313 9.69166 19.8244 10.7332 19.8244 11.984C19.8244 13.2247 19.4313 14.2611 18.6502 15.0882C17.869 15.9102 16.8632 16.3288 15.6175 16.3288Z"
                                fill="#0066CC"/>
                            <path
                                d="M11.3289 22.4605C11.3289 21.2097 11.7322 20.1733 12.5287 19.3462C13.3302 18.5191 14.3513 18.1055 15.6124 18.1055C16.8581 18.1055 17.8741 18.5242 18.6502 19.3768C19.4313 20.2294 19.8244 21.271 19.8244 22.5167"
                                fill="#0066CC"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1156_13356">
                                <rect width="30" height="30" fill="white" transform="translate(0.57666 0.0422363)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span>{__('Entra con SPID / CIE', 'gepafin')}</span>
                </button>

                <div className="appPage__spacer"></div>

                <p><a href={origin} onClick={openCacheFaq}>{__('Hai problemi di accesso con SPID?', 'gepafin')}</a></p>

                <Dialog header={__('Termini e condizioni', 'gepafin')} visible={visibleCacheFaq} style={{
                    width: '70vw',
                    minHeight: 500
                }} onHide={hideCacheFaq}>
                    <p>
                        {__('Se rilevi problemi di accesso allo sportello telematico utilizzando le tue credenziali SPID, solitamente si tratta di un esaurimento dello spazio dedicato alla cache, cioè l’insieme dei dati che i software salvano sul browser per rendere il caricamento di determinate pagine internet più rapido.', 'gepafin')}
                    </p>
                    <Accordion>
                        <AccordionTab header={__('Come svuotare la cache su Google Chrome', 'gepafin')}>
                            <p>Se solitamente navighi in internet utilizzando Google Chrome, per svuotare la cache
                                devi:</p>

                            <ul>
                                <li>cliccare sui tre puntini verticali in alto a destra
                                </li>
                                <li>selezionare la voce Impostazioni</li>
                                <li>accedere al menu Privacy e sicurezza > Cancella dati di navigazione</li>
                                <li>selezionare la voce Immagini e file memorizzati nella cache e poi premere sul
                                    pulsante Cancella dati.
                                </li>
                            </ul>

                            <img src={`${origin}/loaded-files/empty-cache-chrome.png`}
                                 alt={__('Come svuotare la cache su Google Chrome', 'gepafin')}/>
                        </AccordionTab>
                        <AccordionTab header={__('Come svuotare la cache su Mozilla Firefox', 'gepafin')}>
                            <p>Se solitamente navighi in internet utilizzando Mozilla Firefox, per svuotare la cache
                                devi:</p>

                            <ul>
                                <li>cliccare sulle tre linee orizzontali in alto a destra</li>
                                <li>selezionare la voce Impostazioni</li>
                                <li>accedere al menu Privacy e sicurezza > Cookie e dati dei siti web > Elimina
                                    dati...
                                </li>
                                <li>selezionare la voce Contenuti web in cache e poi premere sul pulsante Elimina.</li>
                            </ul>

                            <img src={`${origin}/loaded-files/empty-cache-firefox.png`}
                                 alt={__('Come svuotare la cache su Mozilla Firefox', 'gepafin')}/>
                        </AccordionTab>
                        <AccordionTab header={__('Come svuotare la cache su Microsoft Edge', 'gepafin')}>
                            <p>Se solitamente navighi in internet utilizzando Microsoft Edge, per svuotare la cache
                                devi:</p>

                            <ul>
                                <li>cliccare sui tre puntini orizzontali in alto a destra</li>
                                <li>selezionare la voce Impostazioni</li>
                                <li>accedere al menu Privacy, ricerca e servizi > Cancella i dati di navigazione >
                                    Scegli cosa cancellare
                                </li>
                                <li>selezionare la voce Immagini e file memorizzati nella cache e poi premere sul
                                    pulsante Cancella ora.
                                </li>
                            </ul>

                            <img src={`${origin}/loaded-files/empty-cache-edge.png`}
                                 alt={__('Come svuotare la cache su Microsoft Edge', 'gepafin')}/>
                        </AccordionTab>
                    </Accordion>
                </Dialog>

            </div>
        </div>
    )
}

export default Login;