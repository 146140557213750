import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import { is, isEmpty, isNil } from 'ramda';
import { classNames } from 'primereact/utils';

// components
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

// service
import FormsService from '../../service/forms-service';

// store
import { storeSet } from '../../store';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import BandoService from '../../service/bando-service';

const BandoForms = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [templates, setTemplates] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [forms, setForms] = useState([]);
    const [bandoStatus, setBandoStatus] = useState('');

    const doCreateNewForm = () => {
        navigate(`/bandi/${id}/forms/new`);
    }

    const goToEditBando = () => {
        navigate(`/bandi/${id}`);
    }

    const openBandoFlowManagement = () => {
        navigate(`/bandi/${id}/flow`);
    }

    const goToEditForm = () => {
        if (selectedForm && selectedForm !== 0) {
            navigate(`/bandi/${id}/forms/${selectedForm}`);
        }
    }

    const goToEditFormFromTemplate = () => {
        console.log('goToEditFormFromTemplate', selectedTemplate)
        //navigate(`/bandi/${id}`);
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setBandoStatus(data.data.status);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const forms = data.data.map(o => ({label: o.label, value: o.id}))
            setForms(forms);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetFormsCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        const parsed = parseInt(id)
        const bandoId = !isNaN(parsed) ? parsed : 0;

        setTemplates([
            {label: "Form template", value: 11}
        ])

        storeSet.main.setAsyncRequest();
        BandoService.getBando(id, getCallback, errGetCallback);
        FormsService.getFormsForCall(bandoId, getFormsCallback, errGetFormsCallback);
    }, [id]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Crea o modifica form per il Bando', 'gepafin')}</h1>
                <p>
                    {__('Scegli come vuoi procedere:', 'gepafin')}
                </p>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPage__content">

                {/*<div className="appPageSection__withBorder disabled">
                    <h2>{__('Usa un template', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Scegli tra i template predefiniti e personalizzali', 'gepafin')}</p>
                        <Dropdown
                            id="template"
                            disabled={true}
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.value)}
                            options={templates}
                            optionLabel="label"
                            placeholder={__('Seleziona template', 'gepafin')}/>
                        <Button
                            type="button"
                            outlined
                            disabled={true}
                            onClick={goToEditFormFromTemplate}
                            label={__('Crea', 'gepafin')}
                            icon="pi pi-plus" iconPos="right"/>
                    </div>
                </div>*/}

                <div className="appPageSection__withBorder">
                    <h2>{__('Crea un nuovo Form da Zero', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Inizia con un form completamente vuoto e personalizzabil', 'gepafin')}</p>
                        <Button
                            type="button"
                            disabled={'PUBLISH' === bandoStatus}
                            onClick={doCreateNewForm}
                            label={__('Crea form', 'gepafin')}/>
                    </div>
                </div>

                <div className={classNames(["appPageSection__withBorder", (isEmpty(forms) ? 'disabled' : '')])}>
                    <h2>{__('Modifica form esistente', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Continua a lavorare su un form precedentemente salvato', 'gepafin')}</p>
                        <Dropdown
                            id="form"
                            disabled={isEmpty(forms)}
                            value={selectedForm}
                            onChange={(e) => setSelectedForm(e.value)}
                            options={forms}
                            optionLabel="label"
                            placeholder={__('Seleziona form', 'gepafin')}/>
                        <Button
                            type="button"
                            outlined
                            disabled={!selectedForm}
                            onClick={goToEditForm}
                            label={'PUBLISH' === bandoStatus ? __('Mostra', 'gepafin') : __('Modifica', 'gepafin')}
                            icon="pi pi-cog" iconPos="right"/>
                    </div>
                </div>

                <div className="appPageSection">

                    <div className="row">
                        <Button
                            type="button"
                            outlined
                            onClick={goToEditBando}
                            label={__('Indietro', 'gepafin')}
                            icon="pi pi-arrow-left" iconPos="left"/>

                        <Button
                            type="button"
                            onClick={openBandoFlowManagement}
                            icon="pi pi-sitemap"
                            iconPos="right"
                            label={__('Gestisci flusso dei form', 'gepafin')}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BandoForms;