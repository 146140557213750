import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { uniq } from 'ramda';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Link } from 'react-router-dom';
import ApplicationService from '../../../../service/application-service';
import { storeSet, useStore } from '../../../../store';
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';


const MyLatestSubmissionsTable = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        ApplicationService.getApplications(getApplCallback, errGetApplCallback)
    }, []);

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (data.data.length) {
                setItems(getFormattedBandiData(data.data));
                setStatuses(uniq(items.map(o => o.status)))
                initFilters();
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetApplCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.callEndDate = new Date(d.callEndDate);
            d.modifiedDate = new Date(d.modifiedDate);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            modifiedDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                               placeholder={__('Cerca', 'gepafin')}/>
                </IconField>
            </div>
        );
    };

    const dateModifyBodyTemplate = (rowData) => {
        return formatDate(rowData.modifiedDate);
    };

    const dateEndBodyTemplate = (rowData) => {
        return formatDate(rowData.callEndDate);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter"
                         showClear/>;
    };

    const progressBodyTemplate = (options) => {
        return <ProgressBar value={options.progress} color={'#64748B'}></ProgressBar>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return <Link to={`/imieibandi/${rowData.id}`}>
            {'DRAFT' === rowData.status
                ? <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small"
                        iconPos="right"/>
                : <Button severity="info" label={__('View', 'gepafin')} icon="pi pi-eye" size="small"
                          iconPos="right"/>}
            </Link>
    }

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={10} loading={isAsyncRequest} dataKey="id"
                       filters={filters}
                       globalFilterFields={['name', 'status']}
                       header={header}
                       emptyMessage={__('Nessun dato disponibile', 'gepafin')}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="callTitle" header={__('Bando', 'gepafin')} filter filterPlaceholder="Search by name"
                        style={{ minWidth: '12rem' }}/>
                <Column header={__('Scadenza', 'gepafin')} filterField="callEndDate" dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Ultima modifica', 'gepafin')} filterField="modifiedDate" dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateModifyBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')} filterMenuStyle={{ width: '14rem' }}
                        style={{ width: '120px' }} body={statusBodyTemplate} filter
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Progressi', 'gepafin')}
                        style={{ minWidth: '10rem' }} field="progress" body={progressBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default MyLatestSubmissionsTable;