import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty, isNil } from 'ramda';

// store
import { storeSet, useStore } from '../../store';

// tools
import getNumberWithCurrency from '../../helpers/getNumberWithCurrency';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import renderHtmlContent from '../../helpers/renderHtmlContent';

// api
import BandoService from '../../service/bando-service';
import FaqItemService from '../../service/faq-item-service';
import ApplicationService from '../../service/application-service';

// components
import { Skeleton } from 'primereact/skeleton';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';

const BandoViewBeneficiario = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [newQuestion, setNewQuestion] = useState('');
    const [applicationObj, setApplicationObj] = useState(true);
    const bandoMsgs = useRef(null);
    const toast = useRef(null);

    /*const scaricaBando = () => {

    }*/

    const scaricaModulistica = () => {
        const bandoId = getBandoId();
        BandoService.getBandoPdf(bandoId, getCallPdfCallback, errCallPdfCallback);
    }

    const getCallPdfCallback = (data) => {
        const bandoId = getBandoId();
        const pdfFile = new Blob([data], { type: 'application/zip' })
        const url = window.URL.createObjectURL(pdfFile);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `modulistica-bando-${bandoId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        storeSet.main.unsetAsyncRequest();
    }

    const errCallPdfCallback = (data) => {
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const getBandoId = () => {
        const parsed = parseInt(id)
        return !isNaN(parsed) ? parsed : 0;
    }

    const submitApplication = () => {
        if (applicationObj && applicationObj.id) {
            navigate(`/imieibandi/${applicationObj.id}`);
        } else {
            const bandoId = getBandoId();
            ApplicationService.createApplication(bandoId, {}, createApplCallback, errCreateApplCallback, [['companyId', chosenCompanyId]]);
        }
    }

    const createApplCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'SUCCESS') {
            navigate(`/imieibandi/${data.data.id}`);
        }
    }

    const errCreateApplCallback = (data) => {
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        if (bandoMsgs.current && data.message) {
            bandoMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        storeSet.main.unsetAsyncRequest();
    }

    /*const saveToFavourites = () => {

    }*/

    const submitNewQuestion = () => {
        if (newQuestion && chosenCompanyId && 0 !== chosenCompanyId) {
            if (bandoMsgs.current) {
                bandoMsgs.current.clear();
            }
            const obj = {
                'id': null,
                'lookUpDataId': null,
                'title': newQuestion,
                'value': newQuestion,
                'response': '',
                'isVisible': false
            }
            storeSet.main.setAsyncRequest();
            FaqItemService.addQuestion(id, obj, createCallBack, errCreateCallback, [['companyId', chosenCompanyId]])
        }
    }

    const createCallBack = (data) => {
        if (data.status === 'SUCCESS') {
            setNewQuestion('');
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCreateCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const getBandoCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedBandiData(data.data));
        }
    }

    const errGetBandoCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
    }

    const getFormattedBandiData = (data) => {
        data.dates = data.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
        return data;
    };

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (data.data.length) {
                setApplicationObj(data.data[0]);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetApplCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    useEffect(() => {
        const bandoId = getBandoId();
        storeSet.main.setAsyncRequest();
        BandoService.getBando(bandoId, getBandoCallback, errGetBandoCallback);
        ApplicationService.getApplications(getApplCallback, errGetApplCallback, [['callId', bandoId]])
    }, [id]);

    return (
        <div className="appPage">
            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__pageHeader">
                    <h1>{data.name}</h1>
                    <p>
                        {__('Data:', 'gepafin')}
                        <span>{getDateFromISOstring(data.createdDate)}</span>
                    </p>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                </>}

            <div className="appPage__spacer"></div>
            <Messages ref={bandoMsgs}/>
            <Toast ref={toast}/>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    {!isEmpty(data.images)
                        ? <picture className="appPageSection__hero">
                            <source srcSet={data.images[0] ? data.images[0].filePath : ''}/>
                            <img src={data.images[0] ? data.images[0].filePath : ''} alt={data.name}/>
                        </picture> : null}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione breve', 'gepafin')}</h2>
                        <div className="row rowContent">
                            {renderHtmlContent(data.descriptionShort)}
                        </div>
                    </div>

                    <div className="appPageSection__row">
                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo totale', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amount)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo minimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMin)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo massimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMax)}</span>
                            </p>
                        </div>

                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Data apertura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[0])} {data.startTime}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Data chiusura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[1])} {data.endTime}</span>
                            </p>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione dettagliata', 'gepafin')}</h2>
                        <div className="row rowContent">
                            {renderHtmlContent(data.descriptionLong)}
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Requisiti di Partecipazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.aimedTo.map((o, i) => <li key={i}>
                                    {o.value}
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Documentazione Richiesta', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <p>{renderHtmlContent(data.documentationRequested)}</p>
                        </div>
                    </div>

                    {/*<div className="appPageSection__withBorder">
                        <h2>{__('Criteri di Valutazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.criteria.map((o, i) => <li key={i}>
                                    {o.value} {o.score > 0 ? sprintf(__(' (%d punti)'), o.score) : null}
                                </li>)}
                            </ul>
                        </div>
                    </div>*/}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Allegati', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.docs.map((o, i) => <li key={i}>
                                    <a href={o.filePath} target="_blank" rel="noreferrer">{o.name}</a>
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('FAQ', 'gepafin')}</h2>
                        <Accordion>
                            {data.faq
                                .filter(o => o.isVisible)
                                .map((o, i) => <AccordionTab key={i} header={renderHtmlContent(o.value)}>
                                    <p>
                                        {renderHtmlContent(o.response)}
                                    </p>
                                </AccordionTab>)}
                        </Accordion>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Non hai trovato la risposta che cercavi?', 'gepafin')}</h2>
                        <div className="appForm__field">
                            <label htmlFor="newQuestion">{__('Fai una domanda', 'gepafin')}</label>
                            <Editor
                                id="newQuestion"
                                value={newQuestion}
                                placeholder={__('Digita qui la tua domanda', 'gepafin')}
                                headerTemplate={header}
                                onTextChange={(e) => setNewQuestion(e.htmlValue)}
                                style={{ height: 80 * 3 }}
                                aria-describedby="newQuestion-help"
                            />
                            <small id="newQuestion-help">
                                {__('Riceverai una notifica quando ti risponderemo', 'gepafin')}
                            </small>
                        </div>
                        <Button
                            type="button"
                            onClick={submitNewQuestion}
                            label={__('Salva', 'gepafin')}/>
                    </div>

                    {!chosenCompanyId || chosenCompanyId === 0
                        ? <>
                            <Message severity="error"
                                     text={__('Devi creare un\'azienda prima di partecipare nei bandi. Vai nel profilo aziendale.', 'gepafin')}/>
                        </>
                        : null}

                    <div className="appPageSection">
                        <h2>{__('Download Documenti', 'gepafin')}</h2>
                        <div className="appPageSection__actions">
                           {/* <Button
                                type="button"
                                disabled={true}
                                outlined
                                onClick={scaricaBando}
                                label={__('Scarica Bando Completo', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>*/}
                            <Button
                                type="button"
                                outlined
                                onClick={scaricaModulistica}
                                label={__('Scarica Bando Completo e Modulistica', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>
                            <Button
                                type="button"
                                disabled={isAsyncRequest || chosenCompanyId === 0}
                                onClick={submitApplication}
                                label={__('Presenta Domanda', 'gepafin')}
                                icon="pi pi-save" iconPos="right"/>
                            {/*<Button
                                type="button"
                                outlined
                                rounded
                                disabled={true}
                                onClick={saveToFavourites}
                                label={__('Aggiungi a preferiti', 'gepafin')}
                                icon="pi pi-heart" iconPos="left"/>*/}
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Contatti per Assistenza', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <p>Email: {data.email}</p>
                            {!isNil(data.phoneNumber) ?
                                <p>{__('Telefono', 'gepafin')}: +39 {data.phoneNumber}</p> : null}
                        </div>
                    </div>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default BandoViewBeneficiario;