import React, { useRef, useEffect, useState, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { __ } from '@wordpress/i18n';
import { head, isEmpty, isNil, pluck } from 'ramda';
import { diff } from 'deep-object-diff';
import { klona } from 'klona';

// components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Dropdown } from 'primereact/dropdown';

const FormFieldRepeater = ({
                               data,
                               setDataFn,
                               fieldName,
                               options = [],
                               errors,
                               register,
                               label,
                               infoText,
                               config = {},
                               disabled = false
                           }) => {
    const forMenu = useRef(null);
    const [stateFieldData, setStateFieldData] = useState([]);
    const [stateOptionsData, setStateOptionsData] = useState([]);
    const menuItems = [
        {
            type: 'existing',
            label: __('Esistente', 'gepafin'),
            command: (data) => {
                setStateFieldData([...stateFieldData, { id: null, value: '', lookUpDataId: 0 }]);
            }
        },
        {
            type: 'new',
            label: __('Nuovo', 'gepafin'),
            command: (data) => {
                setStateFieldData([...stateFieldData, { id: null, value: '', lookUpDataId: null }]);
            }
        }
    ];

    const removeItem = (index) => {
        const newData = stateFieldData.toSpliced(index, 1);
        setStateFieldData(newData);
    }

    const selectItem = (e, index) => {
        const targetedOption = head(stateOptionsData.filter(o => o.value === e.value));

        if (targetedOption) {
            const newData = stateFieldData.map((o, i) => {
                return i === index ? targetedOption : o;
            })
            setStateFieldData(newData);
        }
    }

    const onInputChange = (e, index) => {
        const { value } = e.target;
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.value = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const properField = (item, i) => {
        return !isNil(item.lookUpDataId)
            ? <Dropdown value={item.value}
                        disabled={disabled}
                        onChange={(e) => selectItem(e, i)}
                        optionDisabled={(opt) => usedExistingValues().includes(opt.value)}
                        options={stateOptionsData}
                        optionLabel="value"/>
            : <InputText disabled={disabled} value={item.value} onInput={(e) => onInputChange(e, i)}/>
    }

    const usedExistingValues = useCallback(() => {
        return stateFieldData
            .filter(o => o.lookUpDataId > 0)
            .map(o => o.value)
    }, [stateFieldData]);

    useEffect(() => {
        const storeFieldData = data ?? [];
        setStateFieldData(storeFieldData);
        register(fieldName, config);
    }, []);

    useEffect(() => {
        const diffData = diff(data, stateFieldData);

        if (!isEmpty(diffData)) {
            const storeFieldData = data ?? [];
            setStateFieldData(storeFieldData);
            setStateOptionsData(prevState => {
                const ids = pluck('lookUpDataId', prevState)
                const objectsToAdd = klona(storeFieldData)
                    .filter(o => !ids.includes(o.lookUpDataId))
                    .map(o => ({ ...o, id: null }));
                return [...prevState, ...objectsToAdd];
            });
        }
    }, [data]);

    useEffect(() => {
        setStateOptionsData([...options]);
    }, [options]);

    useEffect(() => {
        setDataFn(fieldName, [...stateFieldData], { shouldValidate: true });
    }, [stateFieldData]);

    return (
        <div className={classNames(['appForm__field', 'formfieldrepeater'])}>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}
            </label>
            {stateFieldData.map((o, i) => <div key={i} className={classNames('appForm__repeaterItem')}>
                <div className="p-inputgroup flex-1">
                    {properField(o, i)}
                    <Button disabled={disabled} type="button" icon="pi pi-times" className="p-button-danger" onClick={() => removeItem(i)}/>
                </div>
                {isNil(o.lookUpDataId) && infoText ? <small>{infoText}</small> : null}
            </div>)}
            <Menu model={menuItems} popup ref={forMenu} id="aimedForMenu"/>
            <Button type="button" iconPos="right" label={__('Aggiungi', 'gepafin')}
                    disabled={disabled}
                    icon="pi pi-chevron-down" onClick={(event) => forMenu.current.toggle(event)}
                    aria-controls="aimedForMenu" aria-haspopup/>
        </div>
    )
}

export default FormFieldRepeater;