import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import { intersection } from 'ramda';

// store
import { storeSet, useStore, useTrackedStore } from '../../store';

// components
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';

const TopBarProfileMenu = ({ menuLeftRef }) => {
    const navigate = useNavigate();
    const userData = useTrackedStore().main.userData();
    const fulleName = `${userData.firstName} ${userData.lastName}`;
    const permissions = useStore().main.getPermissions();
    const companies = useStore().main.companies();
    const chosenCompanyId = useStore().main.chosenCompanyId()
    const [companyItems, setCompanyItems] = useState([]);
    const renderCompanyItem = (item) => (
        <span className="topBar__menuCompanyItem"
              onClick={() => switchCompany(item.companyId)}
              data-id={item.companyId}
              data-active={chosenCompanyId === item.companyId}>
            <i className="pi pi-building-columns"></i>
            {item.label}
        </span>
    );
    const toast = useRef(null);
    let items = [
        {
            template: (item, options) => {
                return (
                    <div className="topBar__menuProfileItem">
                        {/*<Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />*/}
                        <div className="userInfo">
                            <span className="userName" title={fulleName}>{fulleName}</span>
                            <span className="userEmail" title={userData.email}>{userData.email}</span>
                        </div>
                    </div>
                );
            },
            enable: true
        },
        {
            label: __('Il mio profilo', 'gepafin'),
            command: () => {
                navigate('/profilo')
            },
            enable: true
        },
        {
            label: __('Profilo aziendale', 'gepafin'),
            command: () => {
                navigate('/profilo-aziendale')
            },
            enable: !intersection(permissions, ['MANAGE_TENDERS']).length && companies.length > 0
        },
        {
            label: __('Seleziona azienda', 'gepafin'),
            items: companyItems,
            enable: companies.length
        },
        {
            label: __('Aggiungi Azienda', 'gepafin'),
            command: () => {
                navigate('/agguingi-azienda')
            },
            enable: !intersection(permissions, ['MANAGE_TENDERS']).length
        },
        {
            separator: true,
            enable: true
        },
        {
            label: __('Logout', 'gepafin'),
            icon: 'pi pi-sign-out',
            command: () => {
                storeSet.main.doLogout();
            },
            enable: true
        }
    ].filter(o => o.enable);

    const switchCompany = (id) => {
        if (chosenCompanyId !== id) {
            storeSet.main.chosenCompanyId(id);
            console.log('set company 2', id)
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('L\'azienda è stata cambiata')
                });
            }
        }
    }

    useEffect(() => {
        const items = companies.map(o => ({
            label: o.companyName,
            companyId: o.id,
            template: renderCompanyItem
        }));
        setCompanyItems(items);
    }, [companies, chosenCompanyId])

    return <>
        <Toast ref={toast}/>
        <Menu model={items} popup ref={menuLeftRef} id="topBar_profileMenu" className="topBar__menuProfile"/>
    </>
}

export default TopBarProfileMenu;