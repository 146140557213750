import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isNil, isEmpty } from 'ramda';

// store
import { storeSet, useStore } from '../../store';

// api
import BandoService from '../../service/bando-service';

// tools
import getBandoLabel from '../../helpers/getBandoLabel';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';

// components
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Steps } from 'primereact/steps';
import BandoEditFormStep1 from './components/BandoEditFormStep1';
import BandoEditFormStep2 from './components/BandoEditFormStep2';
import { Messages } from 'primereact/messages';
import FormsService from '../../service/forms-service';
import BlockingOverlay from '../../components/BlockingOverlay';
import { Toast } from 'primereact/toast';

const BandoEdit = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const navigate = useNavigate();
    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(null)
    const [data, setData] = useState({});
    const [forms, setForms] = useState([]);
    const formRef = useRef(null);
    const bandoMsgs = useRef(null);
    const toast = useRef(null);

    const stepItems = [
        {
            label: __('Testi', 'gepafin'),
            command: () => {
                if (activeStep === 0) {
                    return false
                }
                bandoMsgs.current.clear();
                goToStep(0);
            }
        },
        {
            label: __('Gestione', 'gepafin'),
            command: () => {
                if (activeStep === 1) {
                    return false
                }
                bandoMsgs.current.clear();
                goToStep(1);
            }
        }
    ];

    const goToStep = (step) => {
        setActiveStep(step);
    }

    const openBandoFormManagement = () => {
        navigate(`/bandi/${id}/forms`);
    }

    const openBandoFlowManagement = () => {
        navigate(`/bandi/${id}/flow`);
    }

    const validateBando = () => {
        storeSet.main.setAsyncRequest();
        bandoMsgs.current.clear();
        BandoService.validateBando(id, validateCallback, errValidateCallback);
    }

    const validateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData({...data, status: data.data.status});
            if (bandoMsgs.current) {
                bandoMsgs.current.show([
                    {
                        id: '99',
                        sticky: true, severity: 'info', summary: '',
                        detail: __('Potrai pubblicare il tuo Bando.', 'gepafin'),
                        closable: false
                    }
                ]);
                if (toast.current) {
                    toast.current.show({
                        severity: 'info',
                        summary: '',
                        detail: __('Potrai pubblicare il tuo Bando.', 'gepafin')
                    });
                }
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errValidateCallback = (data) => {
        if (data.status === 'VALIDATION_ERROR') {
            storeSet.main.unsetAsyncRequest();
            if (bandoMsgs.current) {
                bandoMsgs.current.show(data.data.map((v, i) => ({
                    id: i,
                    sticky: true, severity: 'error', summary: '',
                    detail: v,
                    closable: false
                })));
            }
        } else {
            standardErrCallback(data);
        }
    }

    const publishBando = () => {
        storeSet.main.setAsyncRequest();
        bandoMsgs.current.clear();
        BandoService.updateBandoStatus(id, publishCallback, errPublishCallback, [['status', 'PUBLISH']]);
    }

    const publishCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (bandoMsgs.current) {
                bandoMsgs.current.show([
                    {
                        id: '99',
                        sticky: true, severity: 'success', summary: '',
                        detail: __('Pubblicato!', 'gepafin'),
                        closable: false
                    }
                ]);
            }
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('Pubblicato!', 'gepafin')
                });
            }
            setData(data.data);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errPublishCallback = (data) => {
        standardErrCallback(data);
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (!isNil(data.data.dates) && data.data.dates.length) {
                data.data.dates = data.data.dates.map(v => is(String, v) ? new Date(v) : v);
            }

            if (data.data.status === 'READY_TO_PUBLISH') {
                bandoMsgs.current.clear();
                bandoMsgs.current.show([
                    {
                        id: '1',
                        sticky: true, severity: 'info', summary: '',
                        detail: __('Potrai pubblicare il tuo Bando.', 'gepafin'),
                        closable: false
                    }
                ]);
            } else if (data.data.status === 'DRAFT') {
                if (bandoMsgs.current) {
                    bandoMsgs.current.clear();
                    bandoMsgs.current.show([
                        {
                            id: '1',
                            sticky: true, severity: 'info', summary: '',
                            detail: __('Potrai pubblicare il tuo Bando solo dopo aver completato tutti i campi obbligatori contrassegnati dagli asterischi.', 'gepafin'),
                            closable: false
                        }
                    ]);
                }
            }

            setData(data.data);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const standardErrCallback = (data) => {
        if (bandoMsgs.current && data.message) {
            bandoMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const getFormsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setForms(data.data);
        }
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        setActiveStep(0);

        const parsed = parseInt(id)
        const bandoId = !isNaN(parsed) ? parsed : 0;

        if (bandoId === 0) {
            setData({
                status: null
            });
            storeSet.main.unsetAsyncRequest();

            if (bandoMsgs.current) {
                bandoMsgs.current.clear();
                bandoMsgs.current.show([
                    {
                        id: '1',
                        sticky: true, severity: 'info', summary: '',
                        detail: __('Potrai pubblicare il tuo Bando solo dopo aver completato tutti i campi obbligatori contrassegnati dagli asterischi.', 'gepafin'),
                        closable: false
                    }
                ]);
            }
        } else {
            BandoService.getBando(id, getCallback, errGetCallback);
            FormsService.getFormsForCall(id, getFormsCallback, () => {});
        }
    }, [id]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Creazione/Modifica Bando', 'gepafin')}</h1>
                <p>
                    {__('Stato:', 'gepafin')}
                    <span>{getBandoLabel(data.status)}</span>
                </p>
            </div>

            <div className="appPage__spacer"></div>

            {!isEmpty(data)
                ? <Steps
                    model={stepItems}
                    activeIndex={activeStep}
                    readOnly={isNil(data.status)}/>
                : null}
            <BlockingOverlay shouldDisplay={isAsyncRequest}/>

            <div className="appPage__spacer"></div>

            <Messages ref={bandoMsgs}/>
            <Toast ref={toast} />

            {!isEmpty(data)
                ? <>
                    {activeStep === 0
                        ? <BandoEditFormStep1 initialData={data} ref={formRef} status={data.status}/> : null}
                    {activeStep === 1
                        ? <BandoEditFormStep2 initialData={data} ref={formRef} status={data.status}/> : null}

                    <div className="appPageSection">
                        <h2>{__('Crea o modifica il Form compilabile dal Beneficiario', 'gepafin')}</h2>

                        <div className="row">
                            <Button
                                type="button"
                                disabled={!['DRAFT', 'PUBLISH', 'READY_TO_PUBLISH'].includes(data.status)}
                                outlined={data.status === 'PUBLISH'}
                                onClick={openBandoFormManagement}
                                label={__('Crea/modifica form', 'gepafin')}/>

                            <Button
                                type="button"
                                disabled={!['DRAFT', 'PUBLISH', 'READY_TO_PUBLISH'].includes(data.status)}
                                outlined={data.status === 'PUBLISH'}
                                onClick={openBandoFlowManagement}
                                icon="pi pi-sitemap"
                                iconPos="right"
                                label={__('Gestisci flusso dei form', 'gepafin')}/>
                        </div>

                        {forms.length
                            ? <ul className="">
                                {forms.map(o => <li key={o.id}>
                                    {o.label}
                                </li>)}
                            </ul>
                            : <p>{__('Nessun modulo creato ancora', 'gepafin')}</p>}
                    </div>

                    <div className="appPage__spacer"></div>

                    <div className="appPageSection">
                        <h2>{__('Pubblica il form', 'gepafin')}</h2>

                        <div className="row">
                            <Button
                                type="button"
                                outlined
                                disabled={!(data.status === 'DRAFT')}
                                onClick={validateBando}
                                label={__('Validate', 'gepafin')}/>
                            <Button
                                type="button"
                                disabled={!(data.status === 'READY_TO_PUBLISH')}
                                onClick={publishBando}
                                label={__('Publish', 'gepafin')}/>
                        </div>
                    </div>
                </>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )
}

export default BandoEdit;