import React from 'react';

const LogoIcon = () => {
    return <svg width="118" height="50" viewBox="0 0 118 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_249_3012" maskUnits="userSpaceOnUse" x="0" y="0" width="118"
              height="50">
            <path d="M118 0H0V50H118V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_249_3012)">
            <path
                d="M40.4767 0C40.7262 0.125 40.9757 0.1875 41.2252 0.3125C42.223 0.6875 42.8467 1.625 42.9091 2.6875C42.9715 3.25 42.9715 3.8125 42.9715 4.375C42.9715 9.3125 42.9715 14.3125 42.9715 19.25C42.9715 22.75 42.9715 26.3125 42.9715 29.8125C42.9715 30.0625 42.9715 30.1875 42.6596 30.1875C39.1047 30.1875 35.5497 30.1875 31.9947 30.1875C31.6829 30.1875 31.4334 30.1875 31.1216 30.1875C30.9345 30.1875 30.8721 30.25 30.8721 30.4375C30.8721 31.4375 30.8721 32.5 30.8721 33.5C30.8721 33.6875 30.9345 33.75 31.1216 33.75C31.4958 33.75 31.9323 33.75 32.3066 33.75C35.6121 33.75 38.9175 33.75 42.223 33.75C42.2854 33.75 42.3478 33.75 42.5349 33.75C42.4101 34.125 42.2854 34.5 42.1607 34.875C40.9133 37.75 38.6681 39.4375 35.7368 40.25C34.5518 40.5625 33.2421 40.625 31.9947 40.5C29.7495 40.25 27.629 39.1875 26.0698 37.5625C24.6353 36 23.6998 34 23.4503 31.875C23.2008 30 23.3879 28.125 24.074 26.375C26.0698 21.375 31.7452 18.9375 36.7347 20.9375C37.1712 21.125 37.6078 21.3125 37.982 21.5625C38.1068 21.625 38.1691 21.625 38.2939 21.5C38.8552 20.4375 39.3541 19.375 39.9154 18.375C40.0402 18.125 39.9154 18.0625 39.7283 18C37.982 17.125 36.0486 16.625 34.1152 16.5C31.3087 16.25 28.5021 16.875 26.0074 18.3125C23.6374 19.625 21.704 21.625 20.519 24.0625C18.648 27.9375 18.5856 32.4375 20.3319 36.375C21.018 37.8125 21.9535 39.125 23.0761 40.25C24.5729 41.875 26.5063 43.0625 28.6269 43.6875C28.7516 43.75 28.8763 43.8125 28.9387 43.875C28.3774 43.875 27.7537 43.9375 27.1924 43.9375C20.9556 43.9375 14.7188 43.9375 8.48203 43.9375C6.73573 43.9375 4.92706 43.9375 3.18076 43.9375C2.12051 44 1.12262 43.4375 0.623679 42.5625C0.498943 42.3125 0.374207 42.0625 0.311839 41.75C0.311839 41.625 0.249471 41.5625 0.187104 41.4375L0 41.625V2.625C0.0623679 2.5 0.0623679 2.4375 0.124736 2.3125C0.311839 1.1875 1.18499 0.3125 2.30761 0.125C2.36998 0.125 2.43235 0.0625 2.49471 0.0625L2.30761 0H40.4767Z"
                fill="#4A644E"/>
            <path
                d="M62.8668 42.125C62.8668 42.25 62.8668 42.3125 62.8668 42.4375C62.8668 43.8125 62.8668 45.1875 62.8044 46.625C62.8044 47.625 62.3679 48.625 61.6195 49.3125C61.0582 49.8125 60.3097 50.0625 59.6237 50.0625V30.5625H62.7421V32.5625C62.8044 32.5 62.8668 32.5 62.8668 32.4375C63.6152 31.25 64.8626 30.5 66.2971 30.3125C68.1057 30 69.9768 30.4375 71.4112 31.625C72.5338 32.5625 73.3446 33.875 73.5941 35.3125C74.093 37.25 73.7812 39.3125 72.7833 41.0625C71.4736 43.3125 68.9165 44.5 66.3594 44C65.1121 43.8125 64.0518 43.1875 63.1787 42.25C63.1163 42.1875 63.0539 42.125 62.9916 42.0625L62.8668 42.125ZM70.4757 36.9375C70.4757 36.625 70.4133 36.375 70.351 36.0625C69.7897 34 67.6691 32.75 65.5486 33.3125C65.2992 33.375 65.0497 33.5 64.8002 33.625C62.8668 34.6875 62.1808 37.1875 63.241 39.125C63.241 39.1875 63.3034 39.25 63.3034 39.25C64.3636 41.0625 66.6089 41.6875 68.4176 40.625C69.852 39.9375 70.4757 38.625 70.4757 36.9375Z"
                fill="#4A644E"/>
            <path
                d="M54.6342 39.625L56.9418 41.4375C56.3805 42.125 55.6945 42.75 54.8837 43.1875C51.89 44.875 47.649 44.0625 45.6533 41.375C44.593 39.9375 44.0941 38.1875 44.3435 36.375C44.4683 34.125 45.8404 32.0625 47.8985 31.0625C49.7072 30.0625 51.9524 29.875 53.8858 30.6875C55.445 31.3125 56.63 32.625 57.1289 34.25C57.5032 35.5 57.6903 36.8125 57.6279 38.125C57.6279 38.375 57.5032 38.375 57.3161 38.375C55.944 38.375 54.5719 38.375 53.1374 38.375C51.5782 38.375 50.019 38.375 48.4598 38.375C48.2103 38.375 47.9609 38.375 47.7114 38.3125C47.5243 38.3125 47.4619 38.375 47.4619 38.5625C47.7738 40.1875 49.1459 41.375 50.7674 41.5C52.2019 41.625 53.574 41 54.3848 39.875C54.5095 39.75 54.5719 39.6875 54.6342 39.625ZM47.5243 35.8125H54.26C54.1977 34 53.0127 32.5625 50.518 32.6875C49.0835 32.75 47.5243 34.3125 47.5243 35.8125Z"
                fill="#4A644E"/>
            <path
                d="M77.4609 34.125L75.7769 32.375C75.9017 32.25 76.0264 32.1875 76.0888 32.0625C77.2738 31.0625 78.6459 30.5 80.1427 30.375C81.2653 30.1875 82.3879 30.3125 83.4482 30.5625C85.1945 30.9375 86.5042 32.375 86.7537 34.125C86.8784 34.6875 86.9408 35.3125 86.9408 35.9375C86.9408 38.4375 86.9408 41 86.9408 43.5C86.9408 43.875 86.9408 43.875 86.5666 43.875C85.8182 43.875 85.0698 43.875 84.3213 43.875C84.0719 43.875 84.0095 43.8125 84.0095 43.5625C84.0095 43.0625 84.0719 42.625 84.0719 42.125C84.0719 42.0625 84.0719 42 84.0095 42C83.9471 42.0625 83.8224 42.125 83.76 42.25C82.6374 43.75 80.8287 44.5 78.9577 44.1875C78.0846 44.125 77.2738 43.8125 76.5254 43.375C74.7791 42.1875 74.2801 39.8125 75.4651 38.0625C75.8393 37.5625 76.2759 37.125 76.8372 36.8125C77.8351 36.3125 78.8953 35.9375 80.018 35.875C81.203 35.75 82.4503 35.75 83.6353 35.6875C83.8224 35.6875 83.8848 35.625 83.8848 35.4375C83.8224 33.75 82.6374 33 81.2029 32.875C79.8932 32.625 78.5211 33.125 77.4609 34.125ZM82.6374 37.8125C82.1385 37.875 81.7019 37.875 81.2029 37.9375C80.4545 38 79.6438 38.1875 78.9577 38.5625C78.5211 38.8125 78.2093 39.3125 78.1469 39.8125C78.1469 40.5 78.5835 41.1875 79.2695 41.375C79.8932 41.625 80.5793 41.6875 81.2029 41.5625C82.0137 41.5 82.7621 41.0625 83.1987 40.375C83.6353 39.625 83.8224 38.8125 83.6977 38C83.6977 37.9375 83.5729 37.8125 83.5106 37.8125C83.3235 37.8125 82.9493 37.8125 82.6374 37.8125Z"
                fill="#4A644E"/>
            <path
                d="M109.144 32.6875C109.206 32.625 109.269 32.5 109.331 32.4375C110.079 31.0625 111.514 30.1875 113.073 30.25C114.071 30.1875 115.006 30.4375 115.88 30.875C117.002 31.5625 117.751 32.6875 117.875 34C118 34.5625 118.062 35.1875 118.062 35.75C118.062 38.3125 118.062 40.875 118.062 43.4375C118.062 43.8125 118.062 43.8125 117.688 43.8125C116.815 43.8125 115.942 43.8125 115.069 43.8125C114.819 43.8125 114.757 43.75 114.757 43.5C114.757 41.1875 114.757 38.9375 114.757 36.625C114.757 36.0625 114.695 35.5 114.57 35C114.383 34.0625 113.697 33.375 112.761 33.25C111.763 33.0625 110.765 33.4375 110.079 34.1875C109.58 34.75 109.331 35.4375 109.331 36.125C109.331 38.5625 109.331 40.9375 109.331 43.375C109.331 43.8125 109.331 43.8125 108.957 43.75C108.084 43.75 107.273 43.75 106.4 43.75C106.15 43.75 106.025 43.6875 106.025 43.4375C106.025 39.25 106.025 35 106.025 30.8125C106.025 30.5625 106.088 30.5 106.337 30.5C107.148 30.5 108.021 30.5 108.832 30.5C109.019 30.5 109.081 30.5625 109.081 30.75C109.081 31.3125 109.081 31.875 109.019 32.4375C109.019 32.5 109.019 32.5625 109.019 32.5625L109.144 32.6875Z"
                fill="#4A644E"/>
            <path
                d="M88.1882 33.3125C88.1882 32.4375 88.1882 31.625 88.1882 30.8125C88.1882 30.625 88.2505 30.5 88.5 30.5C89.3108 30.5 90.0592 30.5 90.9324 30.5C90.9324 30.375 90.9324 30.3125 90.9324 30.1875C90.9324 29.25 90.9324 28.3125 90.9947 27.375C91.0571 26.5625 91.2442 25.8125 91.4937 25.0625C91.9302 23.8125 93.0529 22.875 94.3626 22.6875C95.4852 22.5 96.6078 22.5 97.7305 22.6875C97.9176 22.6875 97.9799 22.75 97.9176 22.9375C97.8552 23.75 97.7928 24.5 97.7305 25.3125C97.7305 25.375 97.6681 25.4375 97.6681 25.5C97.1692 25.4375 96.7326 25.375 96.296 25.375C94.8615 25.3125 94.3626 26.1875 94.2379 27.25C94.1755 28.1875 94.1755 29.1875 94.1755 30.125C94.1755 30.375 94.2379 30.4375 94.4873 30.4375C95.2981 30.4375 96.1089 30.4375 96.9197 30.4375C97.1691 30.4375 97.2315 30.5 97.2315 30.75C97.2315 31.5 97.2315 32.25 97.2315 33C97.2315 33.1875 97.2315 33.25 97.0444 33.25C96.1713 33.25 95.2981 33.25 94.4873 33.25C94.2379 33.25 94.2379 33.375 94.2379 33.5625C94.2379 35.5625 94.2379 37.625 94.2379 39.625C94.2379 40.4375 94.0508 41.1875 93.7389 41.9375C93.24 42.875 92.2421 43.5625 91.1818 43.625C91.1195 43.625 91.1195 43.625 91.0571 43.625C91.0571 43.625 90.9947 43.625 90.9324 43.625V43.3125C90.9324 40.125 90.9324 36.9375 90.9324 33.6875C90.9324 33.4375 90.87 33.3125 90.5582 33.3125C89.9345 33.3125 89.3108 33.3125 88.6871 33.3125C88.5 33.375 88.3129 33.3125 88.1882 33.3125Z"
                fill="#4A644E"/>
            <path
                d="M102.72 37.1875C102.72 39.3125 102.72 41.375 102.72 43.5C102.72 43.75 102.657 43.8125 102.408 43.8125C101.535 43.8125 100.599 43.8125 99.7262 43.8125C99.5391 43.8125 99.4144 43.75 99.4144 43.5C99.4144 39.25 99.4144 35.0625 99.4144 30.8125C99.4144 30.5625 99.4767 30.5 99.7262 30.5C100.599 30.5 101.535 30.5 102.408 30.5C102.657 30.5 102.72 30.5625 102.72 30.8125C102.72 32.9375 102.72 35.0625 102.72 37.1875Z"
                fill="#4A644E"/>
            <path
                d="M101.036 28C99.9757 28 99.1649 27.1875 99.0402 26.1875C98.9778 25.4375 99.352 24.6875 100.1 24.3125C101.098 23.75 102.408 24.125 102.969 25.125C103.094 25.3125 103.156 25.5 103.219 25.6875C103.344 26.6875 102.72 27.5625 101.784 27.875C101.472 27.9375 101.223 28 101.036 28Z"
                fill="#4A644E"/>
        </g>
    </svg>
}

export default LogoIcon;