import React, { useEffect, useState } from 'react';
import { head, isEmpty, isNil } from 'ramda';
import { __, sprintf } from '@wordpress/i18n';
import { klona } from 'klona';

// store
import { storeSet, useStore } from '../../../../store';

// components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputSwitch } from 'primereact/inputswitch';
import ElementSetting from './components/ElementSetting';
import { Dropdown } from 'primereact/dropdown';

const BuilderElementSettings = ({ closeSettings }) => {
    const elements = useStore().main.formElements();
    const activeElement = useStore().main.activeElement();
    const [activeElementData, setActiveElementData] = useState({});
    const [settings, setSettings] = useState([]);
    const [validators, setValidators] = useState({});
    const textBasedValidatorFields = ['min', 'max', 'minLength', 'maxLength', 'pattern'];
    const customValidationOptions = [
        { value: 'isPIVA', label: 'isPIVA' },
        { value: 'isCodiceFiscale', label: 'isCodiceFiscale' },
        { value: 'isCAP', label: 'isCAP' },
        { value: 'isIBAN', label: 'isIBAN' },
        { value: 'isEmail', label: 'isEmail' },
        { value: 'isEmailPEC', label: 'isEmailPEC' },
        { value: 'isUrl', label: 'isUrl' },
        { value: 'isMarcaDaBollo', label: 'isMarcaDaBollo' },
        { value: 'minChecks', label: 'minChecks' },
        { value: 'maxChecks', label: 'maxChecks' },
        { value: 'nonEmptyTables', label: 'nonEmptyTables' }
    ]

    const onChange = (value, name) => {
        const newSettings = settings
            .map(o => {
                if (o.name === name) {
                    o.value = value;
                }

                return o;
            });

        setSettings(newSettings);
    }

    const onUpdateOptions = (name, value) => {
        const newSettings = settings
            .map(o => {
                if (o.name === name) {
                    o.value = value;
                }

                return o;
            });

        setSettings(newSettings);
    }

    const saveSettings = () => {
        activeElementData.settings = settings;
        activeElementData.validators = validators;
        const newElements = elements.map(o => o.id === activeElementData.id ? activeElementData : o);
        storeSet.main.formElements(newElements);
        closeSettings();
    }

    const showField = (value, key) => {
        let newValidators = klona(validators);
        if (value) {
            newValidators[key] = '';
        } else {
            newValidators[key] = null;
        }
        setValidators(newValidators);
    }

    const toggleRequired = (value, key) => {
        let newValidators = klona(validators);
        newValidators[key] = value;
        setValidators(newValidators);
    }

    const onChangeValidator = (value, name) => {
        let newValidators = klona(validators);
        newValidators[name] = value;
        setValidators(newValidators);
    }

    useEffect(() => {
        const chosen = head(elements.filter(o => o.id === activeElement));

        if (chosen) {
            setActiveElementData(klona(chosen));
            setSettings(klona(chosen.settings));
            setValidators(klona(chosen.validators))
        } else {
            setActiveElementData({});
            setSettings([]);
            setValidators({})
        }
    }, [activeElement]);

    return (activeElementData
            ? <div className="formElementSettings">
                <Tag value={activeElementData.name} severity="info"/>
                <TabView className="formElementSettings__tabs">
                    <TabPanel header={__('Presentation', 'gepafin')}>
                        {settings
                            ? settings.map((o) => <ElementSetting
                                key={o.name}
                                setting={o}
                                changeFn={onChange}
                                updateDataFn={onUpdateOptions}/>)
                            : null}
                    </TabPanel>
                    {!isEmpty(validators)
                        ? <TabPanel header={__('Validation', 'gepafin')}>
                            {validators
                                ? Object.keys(validators).map((k) => <div
                                    className="formElementSettings__field" key={k}>
                                    {k === 'isRequired'
                                        ? <div className="formElementSettings__field">
                                            <label htmlFor={k}>{__('Obligatorio?', 'gepafin')}</label>
                                            <InputSwitch
                                                checked={validators[k]}
                                                onChange={(e) => toggleRequired(e.value, k)}/>
                                        </div>
                                        : null}
                                    {textBasedValidatorFields.includes(k) || 'custom' === k
                                        ? <div className="formElementSettings__field">
                                            <label htmlFor={`enable_${k}`}>{sprintf(__('Set %s', 'gepafin'), k)}</label>
                                            <InputSwitch
                                                checked={!isNil(validators[k])}
                                                onChange={(e) => showField(e.value, k)}/>
                                        </div>
                                        : null}
                                    {k === 'custom' && !isNil(validators[k])
                                        ? <div className="formElementSettings__field">
                                            <label htmlFor={k}>{__('Personalizzato', 'gepafin')}</label>
                                            <Dropdown
                                                id={`enable_${k}`}
                                                value={validators[k]}
                                                onChange={(e) => onChangeValidator(e.value, k)}
                                                options={customValidationOptions}
                                                optionLabel="label"
                                                optionValue="value"
                                                placeholder={__('Scegli', 'gepafin')}/>
                                        </div>
                                        : null}
                                    {textBasedValidatorFields.includes(k) && !isNil(validators[k])
                                        ? <div className="formElementSettings__field">
                                            <label htmlFor={k}>{k}</label>
                                            <InputText id={k} aria-describedby={`${k}-help`}
                                                       value={validators[k]}
                                                       onChange={(e) => onChangeValidator(e.target.value, k)}/>
                                        </div> : null}
                                </div>) : null}
                        </TabPanel> : null}
                </TabView>

                <Button label={__('Salva', 'gepafin')} onClick={saveSettings}/>
            </div>
            : null
    )
}

export default BuilderElementSettings;